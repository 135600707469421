import React from 'react';
import {Heading, Image} from "@chakra-ui/react";

const BannerText = (props) => {
    return (
        <div className="banner_text">
            <Image src="images/phone3.png" alt="" py={{base: '5', md: 'initial'}}/>
            <Heading as={'h2'} color={'#fff'}>{props.title}</Heading>
        </div>
    );
};

export default BannerText;