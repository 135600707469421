import React from "react";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import {SEO} from "../../Components/SEO/SEO";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SiteData} from "../../Constants/siteData";

export const ResidentialFencing = () => {
    return (
        <PageWrapper>
            <SEO/>
            <PageHeader header={SiteData.residentialFencing.h1}
                        subtitle={SiteData.residentialFencing.subtitle}/>
            <ServicePageTemplate body={SiteData.residentialFencing.body}/>
        </PageWrapper>
    );
};
