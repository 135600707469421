export const posts = [
    {
        "id": "1",
        "title": "Maximizing Your Outdoor Space with Stylish Fencing Ideas",
        "excerpt": "Transform your outdoor living areas with innovative fencing designs that blend style, privacy, and functionality. Explore the latest trends and ideas to enhance your Simi Valley property.",
        "slug": "stylish-fencing-ideas",
        "tags": ["fencing ideas", "outdoor living", "home improvement"],
        "metaDescription": "Discover creative and stylish fencing ideas to maximize your outdoor space, offering privacy, security, and aesthetic appeal for your Simi Valley home.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "2",
        "title": "The Essential Guide to Fence Maintenance and Longevity",
        "excerpt": "Keep your fence looking great and standing strong for years with our essential maintenance tips. Learn how to protect and care for your fencing, regardless of the material.",
        "slug": "fence-maintenance-longevity",
        "tags": ["fence maintenance", "fence longevity", "home care"],
        "metaDescription": "Master the art of fence maintenance with our comprehensive guide. Ensure the longevity and beauty of your fencing in Simi Valley with practical tips and advice.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "3",
        "title": "Choosing the Right Material for Your Fencing Needs",
        "excerpt": "From wood to vinyl, selecting the right fencing material is crucial for your project's success. Compare the pros and cons of different materials to find the best fit for your Simi Valley property.",
        "slug": "choosing-fencing-material",
        "tags": ["fencing materials", "fence design", "property improvement"],
        "metaDescription": "Navigate the world of fencing materials with ease. Learn about the advantages and disadvantages of various options to choose the perfect one for your Simi Valley home.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "4",
        "title": "Innovative Fence Designs to Boost Curb Appeal",
        "excerpt": "Elevate the look of your home with unique and innovative fence designs. Discover how the right fence can add to your home's curb appeal and overall value in Simi Valley.",
        "slug": "innovative-fence-designs",
        "tags": ["fence designs", "curb appeal", "home value"],
        "metaDescription": "Transform your home's exterior with innovative fence designs that stand out. Get inspired by ideas that enhance curb appeal and add value to your Simi Valley property.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    }
]

