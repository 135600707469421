import React from "react";
import {Box, Button, Container, Grid, Heading, Link, ListItem, Stack, Text, UnorderedList,} from "@chakra-ui/react";
import {
    MdBusiness,
    MdBusinessCenter,
    MdConstruction,
    MdFence,
    MdHome,
    MdOutlineLocalFlorist,
    MdOutlinePool,
    MdOutlinePrivacyTip,
} from "react-icons/md";
import {posts} from "./posts";
import {IoMdFlower} from "react-icons/io";
import {GiGreenhouse, GiOpenGate, GiSecurityGate} from "react-icons/gi";
import {RiCustomerService2Line} from "react-icons/ri";
import {BiCertification} from "react-icons/bi";
import {BsTools} from "react-icons/bs";
import {FcAutomatic} from "react-icons/fc";
import {faqs} from "./faqs";

const phoneNumber = "(805) 749-5157";
const telLink = "tel:8057495157";
const phoneNumberWithDashes = "805-749-5157";

export const SiteData = {
    "hoursOfOperation": "Mon - Fri 8AM - 5PM",
    "city": "Simi Valley, CA",
    "phoneNumber": phoneNumber,
    "telLink": telLink,
    "phoneNumberWithDashes": phoneNumberWithDashes,
    "buttonCtaText": "Call Now for Expert Fencing Solutions!",
    "emailFooterText": "For all your fencing needs, call Fencing Simi Valley at 805-749-5157",
    "keywords": "Fencing solutions, privacy, security, decorative fences, Simi Valley, CA.",
    "footerText": "Fencing Simi Valley, All rights reserved.",
    "ogImage": "fencingsimivalleymeta",
    "navLinks": [
        {
            "title": "Home",
            "href": "/"
        },
        {
            "title": "Services",
            "href": "/services/"
        },
        {
            "title": "Blog",
            "href": "/blog/"
        },
        {
            "title": "About",
            "href": "/about/"
        },
        {
            "title": "Contact Us",
            "href": "/contact/"
        },
        {
            "title": "Gallery",
            "href": "/gallery/",
            "hideFromTopNav": false
        }
    ],
    "signupForm": {
        "intro": "When you're looking for premium fencing solutions, Fencing Simi Valley is the top choice. Serving the community of Simi Valley and surrounding areas, our team is qualified to meet any residential or commercial fencing need. We specialize in providing secure, aesthetic, and durable fencing options tailored to your preferences and requirements.",
        "servicesTitle": "Our Fencing Services:"
    },
    "homepage": {
        "url": "https://www.fencingsimivalley.com",
        "metaData": {
            "title": "Expert Fencing Services in Simi Valley | Call 805-749-5157",
            "description": "Fencing Simi Valley is your reliable partner for high-quality fencing solutions in California. Contact us for secure, decorative, and privacy fencing options.",
        },
        "footerHeading": "Reach Out to Fencing Simi Valley",
        "footerText": () => (
            <>
                Connect with us for fencing solutions in the Simi Valley area. We're here to meet all your fencing
                needs, ensuring your satisfaction with our services.
            </>
        ),
        "h1": "Fencing Solutions Specialist in Simi Valley, California",
        "subtitle": "Your Trusted Partner for Secure and Decorative Fencing",
        "footerText2": "Delivering Top-Quality Fencing Solutions in Simi Valley, California",
        "values": [
            {
                "id": 1,
                "number": "20+ Years",
                "name": "Experience in Fencing",
                "icon": MdFence,
            },
            {
                "id": 2,
                "number": "100%",
                "name": "Customer Satisfaction",
                "icon": RiCustomerService2Line,
            },
            {
                "id": 3,
                "number": "Eco-Friendly",
                "name": "Fencing Options",
                "icon": GiGreenhouse,
            },
            {
                "id": 4,
                "number": "Certified",
                "name": "Professional Installers",
                "icon": BiCertification,
            }
        ],
        "services": [
            {
                "id": "0",
                "text": "Secure Perimeter Fencing: Ensuring the safety of your property with durable and reliable perimeter fencing options."
            },
            {
                "id": "1",
                "text": "Privacy Fencing: Offering a range of privacy fencing solutions to create a secluded sanctuary in your own backyard."
            },
            {
                "id": "2",
                "text": "Decorative Fencing: Enhance the curb appeal of your home or business with our stylish and elegant decorative fencing."
            },
            {
                "id": "3",
                "text": "Gate Installation: Complement your fencing with custom gate installation for both security and aesthetic purposes."
            },
            {
                "id": "4",
                "text": "Fence Repair and Maintenance: Providing comprehensive repair and maintenance services to keep your fence in top condition."
            }
        ],
        "servicesHeader": "Comprehensive Fencing Services for All Your Needs",
        "servicesSubtitle": () => (
            <Text color="muted" fontSize={{base: "lg", md: "xl"}}>
                Explore our full suite of services – from secure perimeter to decorative fencing, Fencing Simi Valley is
                your all-encompassing solution for all fencing requirements.
            </Text>
        ),
        "content":
            <>
                <Heading as="h2" size={'md'} pb='2'>Fencing Simi Valley: Expert Fencing Solutions for Every
                    Property</Heading>
                <Text>
                    Welcome to Fencing Simi Valley, your premier destination for comprehensive fencing solutions in Simi
                    Valley, California. Our team is dedicated to providing top-notch fencing services, designed to
                    enhance the security, privacy, and aesthetic appeal of your residential or commercial property. With
                    a commitment to quality, innovation, and customer satisfaction, Fencing Simi Valley stands as your
                    reliable partner for all your fencing needs.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Customized Fencing Services for Simi Valley Residents</Heading>
                <Text>
                    At Fencing Simi Valley, we offer a diverse range of fencing options to suit any requirement or style
                    preference. From classic wood fences to durable vinyl and elegant wrought iron, our expertise spans
                    across various materials and designs. Whether you're looking for enhanced privacy, security, or
                    simply want to add a decorative touch to your property, our skilled team is equipped to deliver the
                    perfect fencing solution.
                </Text>

                <Stack direction={{base: 'column', lg: 'row'}} spacing={{base: '12', lg: '16'}} flex="1">
                    <Box as="main" role="main" width="full" bg="bg.accent.default">
                        <Stack spacing={3}>
                            <Heading as="h2" size={'md'} pb='2'>Tailored Fencing Options to Meet Your Needs</Heading>
                            <Text>
                                Our services at Fencing Simi Valley are crafted to cover all aspects of fencing, from
                                installation to repair and maintenance:
                            </Text>
                            <UnorderedList>
                                <ListItem><Link href="/residential-fencing-simi-valley" fontWeight={"bold"}>Residential
                                    Fencing:</Link> Secure and stylize your home with our residential fencing services,
                                    offering privacy, security, and aesthetic appeal with top-quality
                                    materials.</ListItem>
                                <ListItem><Link href="/commercial-fencing-simi-valley" fontWeight={"bold"}>Commercial
                                    Fencing:</Link> Enhance and protect your business premises with our durable
                                    commercial fencing solutions, customized to meet your specific needs and
                                    requirements.</ListItem>
                                <ListItem><Link href="/privacy-fencing-simi-valley" fontWeight={"bold"}>Privacy
                                    Fencing:</Link> Create a
                                    secluded oasis in your backyard with our privacy fencing options, designed to offer
                                    maximum privacy and tranquility.</ListItem>
                                <ListItem><Link href="/decorative-fencing-simi-valley" fontWeight={"bold"}>Decorative
                                    Fencing:</Link> Add
                                    charm and character to your property with our decorative fencing services, blending
                                    style and functionality for a stunning perimeter.</ListItem>
                                <ListItem><Link href="/gate-installation-simi-valley" fontWeight={"bold"}>Gate
                                    Installation:</Link> Complement your fencing with custom gate installation, offering
                                    secure access and an elegant entryway to your home or business.</ListItem>
                                <ListItem><Link href="/fence-repair-simi-valley" fontWeight={"bold"}>Fence
                                    Repair:</Link> Extend the life
                                    and maintain the beauty of your fence with our professional repair services,
                                    addressing damage and wear with expertise.</ListItem>
                                <ListItem><Link href="/automated-gate-systems-simi-valley" fontWeight={"bold"}>Automated
                                    Gate
                                    Systems:</Link> Enhance convenience and security with our automated gate systems,
                                    integrating technology for easy access control.</ListItem>
                                <ListItem><Link href="/pool-fencing-simi-valley" fontWeight={"bold"}>Pool
                                    Fencing:</Link> Ensure safety and
                                    compliance with our pool fencing services, designed to protect your family while
                                    maintaining aesthetic appeal.</ListItem>
                            </UnorderedList>
                        </Stack>
                    </Box>
                </Stack>

                <Heading as="h2" size={'md'} pb='2'>Advanced Fencing Techniques and Premium Materials</Heading>
                <Text>
                    We pride ourselves on utilizing cutting-edge fencing techniques and sourcing the highest quality
                    materials available. Our commitment to sustainability and excellence ensures that your fence is not
                    only visually appealing but also built to last. Our team continually explores the latest
                    advancements in the fencing industry to bring you innovative and effective fencing solutions.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Commitment to Customer Satisfaction and Excellence</Heading>
                <Text>
                    At Fencing Simi Valley, ensuring customer satisfaction is our highest priority. We strive to exceed
                    your expectations by providing personalized service, meticulous attention to detail, and a seamless
                    project experience from start to finish. Our reputation for excellence and reliability has made us
                    the go-to fencing provider in Simi Valley.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Why Trust Fencing Simi Valley for Your Fencing Needs?</Heading>
                <Text>
                    Choosing Fencing Simi Valley means selecting a partner that values:
                </Text>
                <UnorderedList>
                    <ListItem>Superior craftsmanship and comprehensive fencing solutions.</ListItem>
                    <ListItem>Environmentally responsible practices and materials.</ListItem>
                    <ListItem>Prompt, reliable, and courteous service.</ListItem>
                    <ListItem>Customized fencing options tailored to your unique preferences.</ListItem>
                    <ListItem>Competitive pricing without compromising on quality or durability.</ListItem>
                </UnorderedList>
                <Text>
                    We take pride in serving the Simi Valley community with unmatched fencing services, dedicated to
                    securing and beautifying your property.
                </Text>

                <Text>
                    Ready to enhance your property with professional fencing services? Contact Fencing Simi Valley
                    at <Link href="tel:805-749-5157">805-749-5157</Link> for a personalized consultation and discover
                    why we are Simi Valley's top choice for fencing solutions. Our team is eager to assist you in
                    achieving the perfect balance of function and style for your fencing project.
                </Text>

                <Button as={'a'} href={'tel:805-749-5157'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for
                    Assistance</Button>
            </>,
        "additionalContent":
            <>
                <Container maxW="container.xl">
                    <Box as="section" pt={10}>
                        <Text mb={4}>
                            Situated in the heart of Simi Valley, California, Fencing Simi Valley emerges as your
                            foremost destination for comprehensive fencing solutions. More than just a service provider,
                            we embody the role of safety enhancers and privacy protectors for both residential and
                            commercial spaces. Our unwavering dedication to delivering superior quality, innovative
                            fencing designs, and ensuring customer satisfaction distinguishes us as the fencing partner
                            of choice throughout Simi Valley.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Commitment to Fencing Excellence
                        </Heading>
                        <Text mb={4}>
                            At Fencing Simi Valley, excellence is our benchmark. Our team comprises seasoned fencing
                            professionals, design consultants, and installation experts who bring a rich tapestry of
                            skills and a shared passion for fencing to every project. Harnessing advanced installation
                            techniques, premium-quality materials, and a keen eye for aesthetics, we guarantee not just
                            satisfaction but a transformation that elevates the security and appeal of your property.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Tailored Fencing Solutions for Simi Valley Residents
                        </Heading>
                        <Text mb={4}>
                            Recognizing the diverse needs of the Simi Valley community, we offer a wide array of fencing
                            services:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Wood Fencing:</strong> Craft warm and inviting boundaries with our custom wood
                                fencing options, blending seamlessly with your property’s natural aesthetics.
                            </ListItem>
                            <ListItem>
                                <strong>Vinyl Fencing:</strong> Choose our low-maintenance, durable vinyl fencing for a
                                long-lasting and pristine perimeter.
                            </ListItem>
                            <ListItem>
                                <strong>Iron Fencing:</strong> Add elegance and strength to your property with our
                                wrought iron fencing, offering unmatched durability and design.
                            </ListItem>
                            <ListItem>
                                <strong>Chain Link Fencing:</strong> Secure your space efficiently with our
                                cost-effective, durable chain link fencing solutions.
                            </ListItem>
                            <ListItem>
                                <strong>Automated Gate Systems:</strong> Enhance accessibility and security with our
                                cutting-edge automated gate systems, tailored to fit your lifestyle and needs.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            A Process Dedicated to Your Satisfaction and Security
                        </Heading>
                        <Text mb={4}>
                            Fencing Simi Valley’s approach is crafted to ensure your utmost satisfaction and enhanced
                            security, through every phase of your fencing project:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Custom Consultation:</strong> We initiate every project with an in-depth
                                discussion to understand your vision, needs, and the unique aspects of your property.
                            </ListItem>
                            <ListItem>
                                <strong>Personalized Design and Planning:</strong> Our designs are meticulously tailored
                                to meet your preferences, ensuring a perfect match for your property’s aesthetic and
                                functional requirements.
                            </ListItem>
                            <ListItem>
                                <strong>Professional Installation:</strong> Leveraging advanced techniques and
                                high-quality materials, our <a href={'https://www.druidhillsfence.com'}
                                                               target={"_blank"} rel={"noreferrer"}>fence
                                installation</a> process is designed to ensure your fence is built to last and
                                meets all safety standards.
                            </ListItem>
                            <ListItem>
                                <strong>Meticulous Cleanup:</strong> Post-installation, we ensure a thorough cleanup,
                                leaving your property neat and enhancing the newly installed fence’s beauty.
                            </ListItem>
                            <ListItem>
                                <strong>Guaranteed Satisfaction:</strong> Your satisfaction is paramount, backed by our
                                commitment to quality and the enduring value of our fencing solutions.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Why Fencing Simi Valley Stands Apart
                        </Heading>
                        <Text mb={4}>
                            Choosing Fencing Simi Valley means entrusting your property to experts who prioritize:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>Exceptional craftsmanship across a wide range of fencing solutions.</ListItem>
                            <ListItem>Commitment to using environmentally responsible materials and methods.</ListItem>
                            <ListItem>Reliable, professional service tailored to meet your unique fencing
                                needs.</ListItem>
                            <ListItem>Competitive pricing, ensuring value without compromising on quality.</ListItem>
                        </UnorderedList>
                        <Text>
                            We take pride in being Simi Valley’s premier choice for fencing services, dedicated to
                            securing and beautifying your property.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Frequently Asked Questions (FAQs)
                        </Heading>
                        <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                            {faqs.map(({question, answer}, index) => (
                                <Box
                                    p={5}
                                    // shadow="md"
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    key={index}
                                >
                                    <Heading fontSize="xl">{question}</Heading>
                                    <Text mt={4}>{answer}</Text>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Start Your Fencing Project Today
                        </Heading>
                        <Text mb={4}>
                            If you're ready to enhance your property with high-quality fencing, Fencing Simi Valley is
                            here to guide you from conception to completion. <Link href="tel:805-749-5157">Contact
                            us</Link> today to begin crafting the perfect fencing solution for your space. Discover the
                            impact of expert fencing services and join the ranks of satisfied property owners in Simi
                            Valley.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href="tel:805-749-5157">
                            Contact Us Now
                        </Button>
                    </Box>
                </Container>
            </>,
        "featuredServices": [
            {
                "name": "Residential Fencing",
                "description": "Secure and stylize your home with our residential fencing services, offering privacy, security, and aesthetic appeal with top-quality materials.",
                "icon": MdHome,
                "path": "/residential-fencing-simi-valley/"
            },
            {
                "name": "Commercial Fencing",
                "description": "Enhance and protect your business premises with our durable commercial fencing solutions, customized to meet your specific needs and requirements.",
                "icon": MdBusinessCenter,
                "path": "/commercial-fencing-simi-valley/"
            },
            {
                "name": "Privacy Fencing",
                "description": "Create a secluded oasis in your backyard with our privacy fencing options, designed to offer maximum privacy and tranquility.",
                "icon": MdOutlinePrivacyTip,
                "path": "/privacy-fencing-simi-valley/"
            },
            {
                "name": "Decorative Fencing",
                "description": "Add charm and character to your property with our decorative fencing services, blending style and functionality for a stunning perimeter.",
                "icon": MdOutlineLocalFlorist,
                "path": "/decorative-fencing-simi-valley/"
            },
            {
                "name": "Gate Installation",
                "description": "Complement your fencing with custom gate installation, offering secure access and an elegant entryway to your home or business.",
                "icon": MdConstruction,
                "path": "/gate-installation-simi-valley/"
            },
            {
                "name": "Fence Repair",
                "description": "Extend the life and maintain the beauty of your fence with our professional repair services, addressing damage and wear with expertise.",
                "icon": BsTools,
                "path": "/fence-repair-simi-valley/"
            },
            {
                "name": "Automated Gate Systems",
                "description": "Enhance convenience and security with our automated gate systems, integrating technology for easy access control.",
                "icon": FcAutomatic,
                "path": "/automated-gate-systems-simi-valley/"
            },
            {
                "name": "Pool Fencing",
                "description": "Ensure safety and compliance with our pool fencing services, designed to protect your family while maintaining aesthetic appeal.",
                "icon": MdOutlinePool,
                "path": "/pool-fencing-simi-valley/"
            }
        ]
    },
    "testimonials": {
        "h1": "Testimonials",
        "heading": "Discover why our clients choose us – Fencing Simi Valley is the premier fencing provider in Simi Valley",
        "testimonials": [
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=fencingsv1",
                "name": "Jordan K.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"Fencing Simi Valley did an incredible job with our backyard fence. The team was professional, and the project was completed efficiently. Our new fence has completely transformed our outdoor space, providing both privacy and beauty.\""
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=fencingsv2",
                "name": "Sophia T.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"I was thoroughly impressed by Fencing Simi Valley's dedication to quality and detail. They installed a decorative fence around our property that not only enhances security but also adds significant curb appeal. Their advice and craftsmanship were top-notch.\""
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=fencingsv3",
                "name": "Ethan G.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"We needed a reliable company for a commercial fencing project, and Fencing Simi Valley exceeded our expectations. The team was punctual, communicative, and the work was of exceptional quality. They’ve earned our trust as the best fencing service in Simi Valley.\""
            }
        ]
    },
    services: {
        "metaData": {
            "title": "Fencing Services - Simi Valley, CA | fencingsimivalley.com",
            "description": "Discover our comprehensive range of fencing services in Simi Valley. From privacy fencing to decorative designs, our expert team is equipped to enhance your property's security and aesthetics."
        },
        "h1": "Top-Quality Fencing Solutions for Homes and Businesses in Simi Valley, CA",
        "h2": "Fencing Services We Offer",
        "subtext": "At Fencing Simi Valley, we are dedicated to providing superior fencing services that not only ensure your property's security but also add to its visual appeal, all while maintaining competitive pricing.",
        "footerHeading": "Ready to Secure and Beautify Your Property?",
        "footerText2": "Connect with us at 805-749-5157 or use our online contact form for a free consultation. Discover the Fencing Simi Valley difference today!",
        "footerText": "At Fencing Simi Valley, customer satisfaction is our ultimate goal. For the finest fencing services in Simi Valley, don't hesitate to reach out to us!",
        content: (
            <>
                <Container maxW="container.xl">
                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>Our Fencing Services</Heading>
                        <Text mb={4}>
                            Fencing Simi Valley offers a comprehensive array of fencing services designed to meet the
                            diverse needs of our clients in Simi Valley. Whether you're looking for enhanced privacy,
                            security, or a way to beautify your property, our expert team is equipped to deliver
                            top-notch solutions.
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <Link href="/residential-fencing-simi-valley" color="teal.500">Residential
                                    Fencing:</Link> Secure and beautify your home with our custom residential fencing
                                options.
                            </ListItem>
                            <ListItem>
                                <Link href="/commercial-fencing-simi-valley" color="teal.500">Commercial
                                    Fencing:</Link> Protect your business premises with durable and functional fencing
                                solutions.
                            </ListItem>
                            <ListItem>
                                <Link href="/privacy-fencing-simi-valley" color="teal.500">Privacy
                                    Fencing:</Link> Enhance your privacy with our range of high-quality privacy fences.
                            </ListItem>
                            <ListItem>
                                <Link href="/decorative-fencing-simi-valley" color="teal.500">Decorative
                                    Fencing:</Link> Add aesthetic value to your property with our decorative fencing
                                options.
                            </ListItem>
                            <ListItem>
                                <Link href="/gate-installation-simi-valley" color="teal.500">Gate
                                    Installation:</Link> Complement your fencing with custom gate solutions for enhanced
                                security and style.
                            </ListItem>
                            <ListItem>
                                <Link href="/fence-repair-simi-valley" color="teal.500">Fence Repair:</Link> Extend the
                                life of your fence with our professional repair services.
                            </ListItem>
                        </UnorderedList>
                        <Text>
                            Each service is customized to fit your specific requirements, ensuring your complete
                            satisfaction. Let Fencing Simi Valley transform your property with our exceptional fencing
                            solutions.
                        </Text>
                    </Box>
                </Container>
            </>
        ),
        "services": [
            {
                "id": "0",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/residential-fencing-simi-valley"} color="teal.500">Residential Fencing</Link>:
                        </b> Enhance the privacy and security of your home with our custom residential fencing services.
                        We provide a variety of styles to complement your property and meet your specific needs.
                    </Text>
                )
            },
            {
                "id": "1",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-fencing-simi-valley"} color="teal.500">Commercial Fencing</Link>:
                        </b> Protect your business premises with our durable commercial fencing solutions. Tailored to
                        your property's requirements, our fences offer security with professional appeal.
                    </Text>
                )
            },
            {
                "id": "2",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/privacy-fencing-simi-valley"} color="teal.500">Privacy Fencing</Link>:
                        </b> Create a secluded retreat in your backyard with our privacy fencing options. Designed for
                        both aesthetics and functionality, these fences provide the ultimate privacy and comfort.
                    </Text>
                )
            },
            {
                "id": "3",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/decorative-fencing-simi-valley"} color="teal.500">Decorative Fencing</Link>:
                        </b> Add elegance and charm to your landscape with our decorative fencing services. Our designs
                        range from classic to contemporary, enhancing your property's curb appeal.
                    </Text>
                )
            },
            {
                "id": "4",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/gate-installation-simi-valley"} color="teal.500">Gate Installation</Link>:
                        </b> Complement your fencing with a custom gate. Our gate installation services offer secure,
                        stylish entryways that match your fencing and enhance property accessibility.
                    </Text>
                )
            }
        ],
        "whyh2": "Why Choose Fencing Simi Valley?",
        "whySubtext": () => (
            <>
                Our team at Fencing Simi Valley is committed to excellence in every project we undertake. With years of
                experience serving Simi Valley, we ensure your fencing solutions are not only functional but also
                aesthetically pleasing. Contact us today at <Link href={"tel:+18057495157"}
                                                                  color="teal.500">805-749-5157</Link> to learn more.
            </>
        ),
        "whyServices": [
            {
                "id": "0",
                "text": () => (
                    <Text>
                        <b>Custom Solutions:</b> Every project is unique, and we pride ourselves on delivering custom
                        fencing solutions tailored to each client's specific needs and preferences.
                    </Text>
                ),
            },
            {
                "id": "1",
                "text": () => (
                    <Text>
                        <b>Dedicated Service:</b> Our commitment to customer satisfaction is unwavering. We work closely
                        with you throughout the process to ensure your fencing project exceeds expectations.
                    </Text>
                ),
            },
            {
                "id": "2",
                "text": () => (
                    <Text>
                        <b>Quality Craftsmanship:</b> Our team of skilled professionals uses only the highest quality
                        materials and craftsmanship techniques to construct fences that are both durable and beautiful.
                    </Text>
                ),
            },
            {
                "id": "3",
                "text": () => (
                    <Text>
                        <b>Expertise in Fencing:</b> With extensive knowledge and experience in the fencing industry, we
                        offer expert advice and solutions for all your fencing needs.
                    </Text>
                ),
            },
        ]
    },
    "residentialFencing": {
        "url": "https://www.fencingsimivalley.com/residential-fencing-simi-valley",
        "metaData": {
            "title": "Expert Residential Fencing Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Secure and beautify your home with our custom residential fencing services in Simi Valley, CA. Discover the perfect fence with Fencing Simi Valley."
        },
        "h1": "Custom Residential Fencing in Simi Valley, CA",
        "subtitle": "Enhancing Your Home’s Privacy and Aesthetic with Quality Fencing",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Secure Your Home with Style</Heading>
                    <Text>
                        Your home is your sanctuary, and at Fencing Simi Valley, we understand the importance of
                        providing both security and aesthetic appeal through our residential fencing services. Serving
                        Simi Valley, CA, we offer a variety of fencing options tailored to meet your needs and
                        complement your home’s style. From classic wood fences to durable vinyl and elegant wrought
                        iron, our expert team is here to guide you through selecting the perfect fence to enhance your
                        property’s privacy, security, and curb appeal.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Custom Design Options:</b> Choose from a wide range of materials and styles to
                            match your home’s architecture and your personal taste.</ListItem>
                        <ListItem><b>Enhanced Privacy:</b> Our fences provide the privacy you need to enjoy your outdoor
                            spaces without worry.</ListItem>
                        <ListItem><b>Increased Security:</b> Deter unauthorized access and enhance the safety of your
                            home and loved ones with a sturdy, well-built fence.</ListItem>
                        <ListItem><b>Property Value Boost:</b> A beautiful and well-maintained fence can significantly
                            increase your property’s value and appeal.</ListItem>
                        <ListItem><b>Professional Installation:</b> Our experienced team ensures your fence is installed
                            with precision, using the highest quality materials for longevity and durability.</ListItem>
                    </UnorderedList>
                    <Text>
                        Let Fencing Simi Valley transform your outdoor space into a secure, private retreat. Our
                        commitment to quality and customer satisfaction makes us the premier choice for residential
                        fencing services in Simi Valley, CA. Contact us today to start your journey to a more beautiful
                        and secure home.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "commercialFencing": {
        "url": "https://www.fencingsimivalley.com/commercial-fencing-simi-valley",
        "metaData": {
            "title": "Professional Commercial Fencing Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Elevate your business's security and aesthetic with our commercial fencing services in Simi Valley, CA. Trust Fencing Simi Valley for all your fencing needs."
        },
        "h1": "Premium Commercial Fencing in Simi Valley, CA",
        "subtitle": "Secure and Beautify Your Business Environment",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Protect Your Business with Robust Fencing Solutions</Heading>
                    <Text>
                        In today’s business world, securing your premises is as important as ever. Fencing Simi Valley
                        specializes in providing commercial fencing solutions that meet the unique needs of businesses
                        in Simi Valley, CA. From high-security fencing to decorative ironworks that enhance your
                        property's look, our range of commercial fencing options is designed to offer both security and
                        style.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Custom Security Solutions:</b> Tailored fencing solutions that cater to the
                            specific security needs of your business.</ListItem>
                        <ListItem><b>Enhanced Curb Appeal:</b> Our fences not only secure your property but also
                            contribute to its aesthetic appeal, making a positive first impression on clients and
                            visitors.</ListItem>
                        <ListItem><b>Durable Materials:</b> We use high-quality materials that withstand the test of
                            time and environmental factors, ensuring long-term reliability.</ListItem>
                        <ListItem><b>Expert Installation:</b> Our team of professionals ensures your commercial fence is
                            installed efficiently, with minimal disruption to your business operations.</ListItem>
                        <ListItem><b>Maintenance and Repair Services:</b> We also offer maintenance and repair services
                            to keep your commercial fencing in optimal condition.</ListItem>
                    </UnorderedList>
                    <Text>
                        Whether you’re looking to enhance the security of your warehouse, school, or retail space,
                        Fencing Simi Valley has the expertise to deliver top-notch commercial fencing services. Contact
                        us today to discuss your commercial fencing needs and how we can help secure and beautify your
                        business environment.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "privacyFencing": {
        "url": "https://www.fencingsimivalley.com/privacy-fencing-simi-valley",
        "metaData": {
            "title": "Custom Privacy Fencing Solutions in Simi Valley, CA | fencingsimivalley.com",
            "description": "Create a private oasis with our privacy fencing services in Simi Valley, CA. Fencing Simi Valley offers customized solutions to enhance your privacy and property."
        },
        "h1": "Enhance Your Privacy with Custom Fencing in Simi Valley, CA",
        "subtitle": "Personalized Privacy Fencing to Suit Your Lifestyle",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Create Your Private Retreat</Heading>
                    <Text>
                        Privacy is a precious commodity, and at Fencing Simi Valley, we understand the importance of
                        creating a secluded space for you and your family. Our privacy fencing solutions are designed to
                        provide the utmost in privacy and tranquility, allowing you to enjoy your outdoor spaces without
                        the worry of prying eyes. Tailored to match the aesthetics of your property, our privacy fences
                        combine functionality with style, making them a perfect addition to any home.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Customizable Options:</b> From wood to vinyl, we offer a wide range of materials
                            and designs to create the perfect privacy fence for your property.</ListItem>
                        <ListItem><b>Enhanced Security:</b> A privacy fence not only offers seclusion but also adds an
                            extra layer of security to your home, keeping unwanted visitors out.</ListItem>
                        <ListItem><b>Increased Property Value:</b> An attractive and well-installed privacy fence can
                            significantly increase your property's overall value and appeal.</ListItem>
                        <ListItem><b>Professional Installation:</b> Our skilled team ensures that your privacy fence is
                            installed with precision and care, guaranteeing longevity and durability.</ListItem>
                        <ListItem><b>Comprehensive Consultation:</b> We work closely with you to understand your privacy
                            needs and preferences, ensuring the final product exceeds your expectations.</ListItem>
                    </UnorderedList>
                    <Text>
                        Let Fencing Simi Valley help you transform your yard into a private sanctuary. With our
                        expertise and commitment to quality, we're ready to design and install a privacy fence that
                        reflects your personal style and meets your security needs. Contact us today to start planning
                        your privacy fencing project in Simi Valley, CA.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "decorativeFencing": {
        "url": "https://www.fencingsimivalley.com/decorative-fencing-simi-valley",
        "metaData": {
            "title": "Elegant Decorative Fencing Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Beautify your property with our decorative fencing services in Simi Valley, CA. Choose Fencing Simi Valley for sophisticated designs that complement your landscape."
        },
        "h1": "Beautifying Simi Valley with Custom Decorative Fencing",
        "subtitle": "Transform Your Landscape with Stylish Fencing Options",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Add Elegance and Character to Your Property</Heading>
                    <Text>
                        Decorative fencing from Fencing Simi Valley is not just about boundary setting—it's a statement
                        of style and a reflection of your home's personality. We specialize in designing and installing
                        decorative fences that enhance the beauty of your landscape while providing functionality.
                        Whether you're looking for a timeless wrought iron fence to accentuate your garden or a modern
                        aluminum fence for pool safety that doesn't compromise aesthetics, our team can bring your
                        vision to life.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Versatile Design Choices:</b> Our range of materials and styles ensures that you
                            can find the perfect match for your home's architecture and your personal taste.</ListItem>
                        <ListItem><b>Enhanced Curb Appeal:</b> A well-designed decorative fence significantly boosts
                            your property's curb appeal, potentially increasing its market value.</ListItem>
                        <ListItem><b>Dual Functionality:</b> While focusing on aesthetics, our decorative fences also
                            fulfill practical functions such as security and privacy.</ListItem>
                        <ListItem><b>Expert Craftsmanship:</b> Our experienced team provides meticulous attention to
                            detail, ensuring your decorative fence is not only beautiful but also durable.</ListItem>
                        <ListItem><b>Personalized Consultation:</b> We work closely with you from design to
                            installation, ensuring your decorative fencing project perfectly aligns with your
                            expectations and budget.</ListItem>
                    </UnorderedList>
                    <Text>
                        Discover the impact a decorative fence can make on your property's overall appearance and
                        functionality. Choose Fencing Simi Valley for unparalleled quality and service in decorative
                        fencing. Contact us today to explore the possibilities for your Simi Valley home.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "gateInstallation": {
        "url": "https://www.fencingsimivalley.com/gate-installation-simi-valley",
        "metaData": {
            "title": "Professional Gate Installation Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Secure and enhance your property with our gate installation services in Simi Valley, CA. Fencing Simi Valley offers a wide range of gate options for every need."
        },
        "h1": "Custom Gate Installation in Simi Valley, CA",
        "subtitle": "Combining Security, Functionality, and Style",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Elevate Your Entrance with Expert Gate Installation</Heading>
                    <Text>
                        A gate is more than just an entry point; it's the first impression of your home or business. At
                        Fencing Simi Valley, we specialize in gate installation services that combine security,
                        functionality, and aesthetic appeal. Whether you're in need of a sturdy, secure gate for your
                        driveway or a beautifully designed garden gate, our team has the expertise to deliver solutions
                        tailored to your specific needs and preferences.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Variety of Options:</b> From classic wrought iron to modern automatic gates, we
                            offer a wide selection to suit any architectural style or security requirement.</ListItem>
                        <ListItem><b>Enhanced Security:</b> Our gates are designed not only to beautify but also to
                            fortify your property, providing peace of mind through added security measures.</ListItem>
                        <ListItem><b>Custom Design and Installation:</b> We provide personalized design consultations to
                            ensure your gate perfectly complements your fencing and overall property design.</ListItem>
                        <ListItem><b>Advanced Technology:</b> For those seeking convenience, we offer automated gate
                            installation, including systems with remote access, keypads, and other smart
                            features.</ListItem>
                        <ListItem><b>Professional Service:</b> Our experienced installation team ensures a seamless and
                            efficient process, from the initial design to the final installation, with minimal
                            disruption to your daily routine.</ListItem>
                    </UnorderedList>
                    <Text>
                        Let Fencing Simi Valley transform your property's entrance with our professional gate
                        installation services. Discover the perfect blend of beauty, functionality, and security with a
                        custom gate designed just for you. Contact us today to schedule a consultation and take the
                        first step towards enhancing your Simi Valley property.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "fenceRepair": {
        "url": "https://www.fencingsimivalley.com/fence-repair-simi-valley",
        "metaData": {
            "title": "Expert Fence Repair Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Restore your fence's integrity and appearance with our professional fence repair services in Simi Valley, CA. Trust Fencing Simi Valley for all your fence maintenance needs."
        },
        "h1": "Dependable Fence Repair in Simi Valley, CA",
        "subtitle": "Maintaining the Strength and Beauty of Your Fencing",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Professional Repairs for Long-lasting Fences</Heading>
                    <Text>
                        Fences, while durable, can fall victim to weather, accidents, and time. Fencing Simi Valley
                        specializes in fence repair services that address everything from minor damage to major
                        restorations, ensuring your fence remains strong, functional, and visually appealing. Whether
                        it's wood rot, rusted metal, or a damaged gate, our team has the skills and resources to bring
                        your fence back to its best condition.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Comprehensive Repair Solutions:</b> We handle all types of fence materials and
                            damages, offering solutions that restore your fence's integrity.</ListItem>
                        <ListItem><b>Preventative Maintenance:</b> Our repair services include preventative measures to
                            protect your fence from future damage and extend its lifespan.</ListItem>
                        <ListItem><b>Aesthetic Restoration:</b> Not only do we fix structural issues, but we also ensure
                            your fence's aesthetic appeal is rejuvenated, aligning with your property's overall
                            look.</ListItem>
                        <ListItem><b>Efficient and Reliable Service:</b> We understand the importance of a timely
                            repair, which is why our team works efficiently to minimize disruption while delivering
                            high-quality results.</ListItem>
                        <ListItem><b>Expert Advice:</b> Our team can provide recommendations for ongoing maintenance and
                            care to keep your fence in optimal condition year-round.</ListItem>
                    </UnorderedList>
                    <Text>
                        Don't let a damaged fence compromise your property's security or curb appeal. Turn to Fencing
                        Simi Valley for expert fence repair services in Simi Valley, CA. Contact us today to schedule a
                        repair consultation and ensure your fence stands strong and beautiful.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "automatedGateSystems": {
        "url": "https://www.fencingsimivalley.com/automated-gate-systems-simi-valley",
        "metaData": {
            "title": "Advanced Automated Gate Systems in Simi Valley, CA | fencingsimivalley.com",
            "description": "Upgrade your property's security and convenience with our automated gate systems in Simi Valley, CA. Fencing Simi Valley offers state-of-the-art gate automation solutions."
        },
        "h1": "Innovative Automated Gate Systems in Simi Valley, CA",
        "subtitle": "Enhancing Accessibility and Security with Cutting-Edge Technology",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Seamless Entry with Automated Gates</Heading>
                    <Text>
                        Embrace the convenience and security of the latest in gate technology with Fencing Simi Valley's
                        automated gate systems. Ideal for residential and commercial properties alike, our automated
                        gates provide secure, effortless entry with a variety of control options to suit your lifestyle.
                        From remote controls to smartphone integration, we offer customization to ensure your automated
                        gate system is perfectly aligned with your needs.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Customization Options:</b> Select from swing, slide, and overhead automated gates,
                            along with a range of access controls for ultimate convenience.</ListItem>
                        <ListItem><b>Enhanced Security:</b> Integrated security features like keypad entry, intercom
                            systems, and automatic locks provide peace of mind.</ListItem>
                        <ListItem><b>Expert Installation:</b> Our team specializes in the precise installation of
                            automated gate systems, ensuring reliable operation and longevity.</ListItem>
                        <ListItem><b>Technology Integration:</b> We incorporate the latest technology for easy access
                            and monitoring of your gate system from anywhere.</ListItem>
                        <ListItem><b>Comprehensive Support:</b> Fencing Simi Valley offers full support from design to
                            installation and beyond, including maintenance and troubleshooting.</ListItem>
                    </UnorderedList>
                    <Text>
                        Upgrade to an automated gate system with Fencing Simi Valley and experience the perfect blend of
                        security, convenience, and style. Contact us today to explore our advanced gate automation
                        solutions in Simi Valley, CA.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "poolFencing": {
        "url": "https://www.fencingsimivalley.com/pool-fencing-simi-valley",
        "metaData": {
            "title": "Safe and Stylish Pool Fencing Services in Simi Valley, CA | fencingsimivalley.com",
            "description": "Ensure the safety of your pool area with our stylish and durable pool fencing options in Simi Valley, CA. Fencing Simi Valley provides customized pool fencing solutions."
        },
        "h1": "Custom Pool Fencing for Safety and Elegance",
        "subtitle": "Protect Your Loved Ones with Durable and Attractive Pool Fences",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Enhance Pool Safety with Elegant Fencing</Heading>
                    <Text>
                        Pool safety is paramount, and Fencing Simi Valley offers pool fencing solutions that combine
                        safety features with aesthetic appeal. Our pool fences are designed to prevent unsupervised
                        access to your pool area, protecting children and pets while complementing your landscape
                        design. With a variety of materials and styles available, we ensure your pool fence not only
                        meets safety regulations but also enhances the beauty of your outdoor space.
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Versatile Design Choices:</b> Choose from glass, aluminum, wood, and more, to match
                            your home’s style and your personal preferences.</ListItem>
                        <ListItem><b>Compliance with Safety Standards:</b> Our pool fences meet local and national
                            safety standards, ensuring your peace of mind.</ListItem>
                        <ListItem><b>Custom Fit:</b> We provide tailored solutions to fit the unique layout of your pool
                            area, ensuring optimal safety and aesthetics.</ListItem>
                        <ListItem><b>Durability:</b> Constructed with high-quality materials, our pool fences are built
                            to withstand the elements and provide long-lasting safety.</ListItem>
                        <ListItem><b>Professional Installation:</b> Our experienced team guarantees a seamless
                            installation process, with attention to detail and minimal disruption to your daily
                            life.</ListItem>
                    </UnorderedList>
                    <Text>
                        Secure your pool area with a fence that offers both protection and style. Contact Fencing Simi
                        Valley today for a consultation on our pool fencing solutions in Simi Valley, CA.
                    </Text>
                </Stack>
            </Box>
        )
    },
    "careers": {
        "url": "https://www.fencingsimivalley.com/careers/",
        "metaData": {
            "title": "Fencing Careers in Simi Valley, CA | fencingsimivalley.com",
            "description": "Explore rewarding fencing careers with Fencing Simi Valley. Join our team and contribute to top-tier fencing projects in and around Simi Valley."
        },
        "h1": "Join Our Fencing Team in Simi Valley, CA",
        "h2": [
            "Why Work with Fencing Simi Valley?",
            "Current Fencing Job Openings",
            "Our Commitment to Employee Development and Excellence"
        ],
        "headingText": {
            "whyJoin": "Be part of a leading fencing team dedicated to quality and innovation. At Fencing Simi Valley, we value skill, hard work, and a positive attitude.",
            "openings": "Discover our current openings and find a role that suits your skills in the fencing industry.",
            "commitment": "We are committed to our team's growth, offering training, competitive benefits, and a dynamic work environment."
        },
        "content": [
            <Text>
                Welcome to the careers page at Fencing Simi Valley. Our mission is to enhance the beauty and security of
                our community through top-quality fencing solutions. We are on the lookout for dedicated individuals who
                share our commitment to craftsmanship, innovation, and excellence. Join our team to make a tangible
                impact in Simi Valley and beyond.
            </Text>,
            <Text>
                We are seeking skilled fence installers, designers, and support staff who are passionate about
                delivering exceptional results and eager to grow within the industry. Fencing Simi Valley offers a
                supportive environment where your talents are recognized and nurtured.
            </Text>,
            <Text>
                Our company culture is centered on continuous improvement, offering comprehensive training programs,
                competitive benefits, and access to the latest fencing tools and technology. Our aim is to cultivate a
                workplace where every team member can thrive and achieve their full potential.
            </Text>,
            <Text>
                As a premier fencing service provider in Simi Valley, we pride ourselves on our integrity,
                professionalism, and commitment to customer satisfaction. We provide our team with a positive work
                atmosphere and ample opportunities for career advancement.
            </Text>,
            <Text>
                If you're interested in joining a dynamic and growing team, explore our current job openings. Whether
                you excel in hands-on fencing projects or excel in customer service and project management, there's a
                place for you at Fencing Simi Valley.
            </Text>,
            <Text>
                <Link href="/careers/" color="teal.500">Discover your next career opportunity</Link> with us. Be part of
                a team that's shaping the future of fencing services in Simi Valley. At Fencing Simi Valley, you're not
                just an employee—you're a key player in our journey to success. Apply today and take the next step in
                your professional journey.
            </Text>
        ]
    },
    "about": {
        "url": "https://www.fencingsimivalley.com/about/",
        "metaData": {
            "title": "About Fencing Simi Valley - Premier Fencing Services",
            "description": "Learn about Fencing Simi Valley, your go-to for reliable and aesthetic fencing solutions in Simi Valley, CA. Discover our passion for fencing and client satisfaction."
        },
        "h1": "About Fencing Simi Valley",
        "h2": [
            "Our Dedication to Superior Fencing Services",
            "Meet Our Team of Fencing Experts",
            "Explore Our Range of Fencing Services"
        ],
        "headingText": {
            "commitment": "Dedicated to providing top-notch fencing services, enhancing the security and beauty of Simi Valley properties.",
            "team": "Our team is skilled and passionate, ensuring quality workmanship in every fencing project.",
            "services": "Offering a wide array of fencing services to meet the diverse needs of Simi Valley residents and businesses."
        },
        "services": [
            {
                "id": "0",
                "title": "Residential Fencing",
                "href": "/residential-fencing-simi-valley",
                "icon": MdHome,
                "description": (
                    <Text>
                        Secure your personal oasis with our <Link href="/residential-fencing-simi-valley"
                                                                  color="teal.500">Residential Fencing</Link> services.
                        Tailored to meet the aesthetic and security needs of Simi Valley homes, our fences come in
                        various materials and designs, ensuring privacy, safety, and style.
                    </Text>
                )
            },
            {
                "id": "1",
                "title": "Commercial Fencing",
                "href": "/commercial-fencing-simi-valley",
                "icon": MdBusiness,
                "description": (
                    <Text>
                        Elevate your commercial property's security and curb appeal with our <Link
                        href="/commercial-fencing-simi-valley" color="teal.500">Commercial Fencing</Link> solutions.
                        From perimeter fences to security gates, we offer durable and stylish options that protect and
                        enhance your business environment.
                    </Text>
                )
            },
            {
                "id": "2",
                "title": "Privacy Fencing",
                "href": "/privacy-fencing-simi-valley",
                "icon": MdOutlinePrivacyTip,
                "description": (
                    <Text>
                        Create a secluded retreat with our <Link href="/privacy-fencing-simi-valley" color="teal.500">Privacy
                        Fencing</Link>. Designed to offer the ultimate in privacy and tranquility, our fences are
                        perfect for turning your backyard into a private escape from the outside world.
                    </Text>
                )
            },
            {
                "id": "3",
                "title": "Decorative Fencing",
                "href": "/decorative-fencing-simi-valley",
                "icon": IoMdFlower,
                "description": (
                    <Text>
                        Add a touch of elegance to your property with our <Link href="/decorative-fencing-simi-valley"
                                                                                color="teal.500">Decorative
                        Fencing</Link> options. Beyond functionality, our decorative fences serve as a stunning visual
                        feature that complements your property's landscape and architecture.
                    </Text>
                )
            },
            {
                "id": "4",
                "title": "Gate Installation",
                "href": "/gate-installation-simi-valley",
                "icon": GiOpenGate,
                "description": (
                    <Text>
                        Enhance your fence with custom <Link href="/gate-installation-simi-valley" color="teal.500">Gate
                        Installation</Link>. From elegant entry gates to convenient automated systems, we provide
                        options that add value and functionality, ensuring a seamless blend with your fencing.
                    </Text>
                )
            },
            {
                "id": "5",
                "title": "Fence Repair",
                "href": "/fence-repair-simi-valley",
                "icon": MdConstruction,
                "description": (
                    <Text>
                        Keep your fence in top condition with our <Link href="/fence-repair-simi-valley"
                                                                        color="teal.500">Fence Repair</Link> services.
                        Whether it's wear and tear or weather damage, our team can restore your fence's integrity and
                        appearance, prolonging its life and maintaining your property's security.
                    </Text>
                )
            },
            {
                "id": "6",
                "title": "Automated Gate Systems",
                "href": "/automated-gate-systems-simi-valley",
                "icon": GiSecurityGate,
                "description": (
                    <Text>
                        Combine convenience with security with our <Link href="/automated-gate-systems-simi-valley"
                                                                         color="teal.500">Automated Gate Systems</Link>.
                        Perfect for residential and commercial properties alike, our automated gates offer easy access
                        while ensuring your property remains secure.
                    </Text>
                )
            },
            {
                "id": "7",
                "title": "Pool Fencing",
                "href": "/pool-fencing-simi-valley",
                "icon": MdOutlinePool,
                "description": (
                    <Text>
                        Ensure safety and compliance with our <Link href="/pool-fencing-simi-valley" color="teal.500">Pool
                        Fencing</Link> services. Designed to protect your loved ones and meet safety regulations, our
                        pool fences blend security with aesthetics, making your pool area safe and stylish.
                    </Text>
                )
            }
        ]
    },
    "contact": {
        "url": "https://www.fencingsimivalley.com/contact",
        "metaData": {
            "title": "Contact Fencing Simi Valley | Call 805-749-5157",
            "description": "Get in touch with Fencing Simi Valley for premier fencing services. Ensure your property's perimeter is secure and stylish with our expert team."
        },
        "h1": "Ready to Enhance Your Property's Perimeter?",
        "subtitle": "Thank you for considering Fencing Simi Valley. We're here to provide you with superior fencing solutions tailored to your needs.",
        "sectionHeading": "Why Choose Fencing Simi Valley?",
        "signUpDescription": "Prefer to contact us online? Fill out our form for a prompt response.",
        "feedbackText": "We value feedback and inquiries. Use our form for non-urgent questions, and we'll get back to you promptly.",
        "closingText": "Contact us to see why we're the top choice for fencing in Simi Valley.",
        "valueProps": [
            {
                "title": "Quality and Durability:",
                "description": "We are committed to providing the highest quality materials and craftsmanship, ensuring that your fence stands strong and looks great for years to come."
            },
            {
                "title": "Innovative Solutions:",
                "description": "Our team is equipped with the latest fencing techniques and tools, allowing us to deliver innovative and effective fencing solutions for any property."
            },
            {
                "title": "Transparent Pricing:",
                "description": "We believe in honesty and transparency at every step. Our quotes are clear and detailed, ensuring you understand and agree to all aspects of the project before we begin."
            },
            {
                "title": "Eco-Friendly Options:",
                "description": "Sustainability matters to us. We offer a range of eco-friendly fencing options that not only enhance your property but also protect the environment."
            },
            {
                "title": "Commitment to Satisfaction:",
                "description": "Your satisfaction is our top priority. We take pride in our work, ensuring that every fencing project meets our high standards and exceeds your expectations."
            }
        ]
    },
    "blog": {
        "url": "https://www.fencingsimivalley.com/blog",
        "metaData": {
            "title": "Fencing Simi Valley Insights: Expert Advice & Tips | Blog",
            "description": "Stay updated with the latest in fencing solutions, trends, and tips from Fencing Simi Valley. Our blog is your premier resource for all things fencing."
        },
        "h1": "Fencing Simi Valley Blog",
        "h2": "Latest Fencing Insights and Tips",
        "heading": "Committed to sharing our knowledge and expertise, our blog offers valuable resources to help you make informed decisions about your fencing needs.",
        posts
    },
    "404": {
        "metaData": {
            "title": "Page Not Found - Fencing Simi Valley | Secure & Stylish Fences | (805) 749-5157",
            "description": "Oops! The page you're looking for could not be found. Fencing Simi Valley provides top-quality fencing services. Reach out for unparalleled fencing solutions."
        },
        "h1": "404 - Oops, it seems like a fence was misplaced.",
        "subtitle": "Sorry, the page you are looking for does not exist.",
        "p": "Whether you're looking for residential or commercial fencing solutions, we're here to help. Contact us today at (805) 749-5157."
    }
};
