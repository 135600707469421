export const faqs = [
    {
        "question": "Which fencing wire is best?",
        "answer": "The best fencing wire depends on your specific needs. Galvanized steel wire is durable and resistant to rust, making it ideal for security and livestock fencing. For a more aesthetic appeal with durability, coated wires like vinyl-coated steel offer both strength and visual attractiveness."
    },
    {
        "question": "Which fencing style suits me?",
        "answer": "The fencing style that suits you depends on your priorities, such as privacy, security, aesthetics, or cost. Privacy fences are great for seclusion, picket fences for aesthetic appeal, and chain-link fences for security and affordability. Consider your property's needs and style to determine the best fit."
    },
    {
        "question": "Which fencing lasts the longest?",
        "answer": "Vinyl fencing is known for its longevity, often lasting 20 to 30 years with minimal maintenance. Wrought iron and aluminum fences also offer long lifespans with proper care. Wooden fences, while popular for their appearance, typically require more maintenance and may have a shorter lifespan."
    },
    {
        "question": "Which fencing is best?",
        "answer": "The best fencing material depends on your specific needs. Vinyl offers durability and low maintenance, wood provides natural beauty and versatility, while metal options like aluminum and steel offer strength and security. Assess your priorities such as privacy, security, and maintenance to choose the best option."
    },
    {
        "question": "Which fencing is cheapest?",
        "answer": "Chain-link fencing is often the cheapest option, providing durability and security at a lower cost compared to wood, vinyl, or metal fences. However, it may offer less privacy and aesthetic appeal than other materials."
    },
    {
        "question": "Is fencing effective?",
        "answer": "Yes, fencing can be highly effective for various purposes such as providing privacy, enhancing security, defining property boundaries, and adding aesthetic value to your home. The effectiveness depends on choosing the right type and height of fencing for your specific needs."
    },
    {
        "question": "How much does fencing cost?",
        "answer": "The cost of fencing varies widely based on materials, size, and labor. On average, homeowners spend between $1,500 and $3,000 for a basic wood fence, while vinyl and metal fences may cost more upfront but offer lower maintenance costs over time."
    },
    {
        "question": "What are the fence between houses rules?",
        "answer": "Fence rules between houses vary by location, including height restrictions, setback requirements, and style limitations. It's important to check local zoning laws and homeowners association (HOA) regulations before installing a fence to ensure compliance."
    },
    {
        "question": "How much to add fence to a house?",
        "answer": "Adding a fence to a house can cost anywhere from $1,500 to over $5,000, depending on the material, length, height, and installation costs. Obtain multiple quotes from contractors and consider factors like durability and maintenance when budgeting for a new fence."
    },
    {
        "question": "How to build a good neighbor fence?",
        "answer": "A good neighbor fence is designed to look appealing from both sides, without a clear front or back. To build one, collaborate with your neighbor on design and costs, choose a mutually agreeable style, and ensure the fence is constructed with alternating panels or shared elements for a cohesive look."
    },
    {
        "question": "Warehouse fencing prices?",
        "answer": "Warehouse fencing prices depend on the size of the area, type of fencing material, and security features required. Industrial chain-link fences are commonly used for their cost-effectiveness and security, with prices varying widely based on specifications and installation costs."
    },
    {
        "question": "How close can a fence be to the house?",
        "answer": "The allowable distance of a fence from the house varies by local zoning laws and HOA rules, often ranging from a few inches to several feet. Check local regulations to ensure compliance, especially for fences that may affect property lines or visibility."
    },
    {
        "question": "Is fencing worth it?",
        "answer": "Fencing can be a valuable investment for your property, offering benefits like increased privacy, security, and curb appeal, potentially enhancing your home's value. Consider your specific needs and the long-term benefits when deciding if fencing is worth the investment."
    }
]