import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost1 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Elevate your outdoor living areas with stylish fencing ideas that combine functionality and aesthetics.
                From modern designs to classic styles, there are numerous options to enhance the beauty and privacy of
                your Simi Valley property.
            </Text>
            <Heading as="h3">Exploring Innovative Fencing Designs</Heading>
            <Text>
                Discover the latest trends and innovative designs that can transform your outdoor space:
            </Text>
            <List styleType="disc">
                <ListItem>
                    <strong>Horizontal Slats:</strong> Create a contemporary look with sleek horizontal slats that offer
                    privacy while allowing airflow.
                </ListItem>
                <ListItem>
                    <strong>Vertical Gardens:</strong> Incorporate living elements into your fence by installing
                    vertical gardens, adding greenery and beauty to your outdoor space.
                </ListItem>
                <ListItem>
                    <strong>Metal and Wood Combination:</strong> Combine metal and wood for a unique, modern aesthetic
                    that offers both durability and style.
                </ListItem>
                <ListItem>
                    <strong>Glass Panels:</strong> Opt for sleek glass panels to maintain views while providing a
                    barrier, perfect for properties with stunning landscapes.
                </ListItem>
                <ListItem>
                    <strong>Bamboo Fencing:</strong> Create a tropical oasis with bamboo fencing, offering natural
                    beauty and eco-friendly benefits.
                </ListItem>
            </List>
            <Text>
                Whether you're looking for privacy, security, or simply to enhance the visual appeal of your outdoor
                space, there's a stylish fencing solution for every need. Let our team at [Your Fencing Company] help
                you bring your vision to life with expert installation and craftsmanship.
            </Text>
            <Text>
                Ready to elevate your outdoor living areas with stylish fencing? Contact us today to explore our wide
                range of fencing options and transform your Simi Valley property.
            </Text>
        </Stack>
    );
};
