import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";
import DirectoriesOnTheWeb from "../Pages/DirectoriesOnTheWeb";
import {PrivacyFencing} from "../Pages/Services/PrivacyFencing";
import {DecorativeFencing} from "../Pages/Services/DecorativeFencing";
import {PoolFencing} from "../Pages/Services/PoolFencing";
import {AutomatedGateSystems} from "../Pages/Services/AutomatedGateSystems";
import {FenceRepair} from "../Pages/Services/FenceRepair";
import {GateInstallation} from "../Pages/Services/GateInstallation";
import {ResidentialFencing} from "../Pages/Services/ResidentialFencing";
import {CommercialFencing} from "../Pages/Services/CommercialFencing";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/residential-fencing-simi-valley/',
                element: <ResidentialFencing/>
            },
            {
                path: '/commercial-fencing-simi-valley/',
                element: <CommercialFencing/>
            },
            {
                path: '/privacy-fencing-simi-valley/',
                element: <PrivacyFencing/>
            },
            {
                path: '/decorative-fencing-simi-valley/',
                element: <DecorativeFencing/>
            },
            {
                path: '/gate-installation-simi-valley/',
                element: <GateInstallation/>
            },
            {
                path: '/fence-repair-simi-valley/',
                element: <FenceRepair/>
            },
            {
                path: '/automated-gate-systems-simi-valley/',
                element: <AutomatedGateSystems/>
            },
            {
                path: '/pool-fencing-simi-valley/',
                element: <PoolFencing/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '/directories-on-the-web',
                element: <DirectoriesOnTheWeb/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

